import { Container, Divider } from "semantic-ui-react";
import Lista from "../Administracion/Lista/Lista";
import "./Dashboard.scss";

const Dashboard = () => {
	return (
		<Container fluid>
			<div className="dashboard">
				<div className="title">Administración</div>
				<Lista />
				<Divider />
			</div>
		</Container>
	);
};

export default Dashboard;
