import { Button, Popup, Table } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

import "./Lista.scss";
import {
	useGetAllQuery,
	useGetStatusesQuery,
} from "../../../store/studies/StudiesApi";

const Lista = () => {
	const { data: lista = [] } = useGetAllQuery({ refetchOnMountOrArgChange: true});
	const { data: statuses = [] } = useGetStatusesQuery();

	return (
		<div>
			<Table selectable celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell>ID</Table.HeaderCell>
						<Table.HeaderCell>Cliente</Table.HeaderCell>
						<Table.HeaderCell>Asesor</Table.HeaderCell>
						<Table.HeaderCell>Status</Table.HeaderCell>
						<Table.HeaderCell>Acción</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{lista?.map((item) => {
						if (item.status > 1) {
							return (
								<Table.Row key={item.id}>
									<Table.Cell>{item.id}</Table.Cell>
									<Table.Cell>
										{item.nombre} {item.apellido_paterno}{" "}
										{item.apellido_materno}
									</Table.Cell>
									<Table.Cell>
										{item.nombre_asesor} {item.apellido_paterno_asesor}{" "}
										{item.apellido_materno_asesor}
									</Table.Cell>
									<Table.Cell>
										{statuses?.res?.map((status) => {
											if (status.id === item.status) {
												return status.descripcion;
											} else {
												return "";
											}
										})}
									</Table.Cell>
									<Table.Cell>
										<NavLink to={`/administracion/detalles?id=${item.id}`}>
											<Popup
												content="Ir a los detalles de este estudio"
												trigger={
													<Button className="thisbutton">Detalles</Button>
												}
											/>
										</NavLink>
									</Table.Cell>
								</Table.Row>
							);
						} else {
							return "";
						}
					})}
				</Table.Body>
			</Table>
		</div>
	);
};

export default Lista;
