import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Container, Dropdown, Form } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setError, setErrorCode } from "../../../../store/status";
import { createStudy } from "../../../../store/studies";

import "./NuevoEstudio.scss";
import {
	estadosRepublicaMexicana,
	waitTime,
} from "../../../../constants/constants";

const NuevoEstudio = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	let user_id = useSelector((state) => state.auth.id);

	const formik = useFormik({
		initialValues: {
			asesor: "",
			nombre: "",
			apellido_paterno: "",
			apellido_materno: "",
			rfc: "",
			curp: "",
			nss: "",
			rfc_imagen: "",
			curp_imagen: "",
			afore_imagen: "",
			identificacionanverso_imagen: "",
			identificacionreverso_imagen: "",
			status: 1,
			registro_facturacion: "",
			comprobantepago_imagen: "",
			entidades_federativas: [],
		},
		validationSchema: Yup.object({
			nombre: Yup.string().required("El nombre de pila es requerido"),
			apellido_paterno: Yup.string().required(
				"El apellido paterno es requerido"
			),
			rfc: Yup.string()
				.min(10, "El RFC debe ser mínimo 10 caracteres")
				.max(13, "El RFC debe ser máximo 13 caracteres"),
			curp: Yup.string().length(18, "El CURP debe ser de 18 caracteres"),
			nss: Yup.string().length(11, "El NSS debe ser de 11 caracteres"),
			entidades_federativas: Yup.array().min(
				1,
				"Debes seleccionar al menos una entidad federativa"
			),
		}),
		onSubmit: (formData) => {
			dispatch(
				createStudy(
					user_id,
					`${formData.nombre}`,
					`${formData.apellido_paterno}`,
					`${formData.apellido_materno}`,
					`${formData.rfc}`,
					`${formData.curp}`,
					`${formData.nss}`,
					`${formData.entidades_federativas}`
				)
			);
			setTimeout(() => {
				navigate("/estudios/captura");
			}, waitTime);
		},
	});

	useEffect(() => {
		dispatch(setError(null));
		dispatch(setErrorCode(null));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleCancelar = () => {
		navigate("/estudios/captura");
	};

	return (
		<Container fluid className="container">
			<div className="header">En captura</div>
			<div className="subheader">Nuevo Estudio</div>
			<div className="nuevo-estudio ">
				<Form onSubmit={formik.handleSubmit} className="form">
					<Form.Input
						type="text"
						placeholder="Juan"
						name="nombre"
						label="Nombre de pila *"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.nombre}
						error={formik.touched.nombre && formik.errors.nombre}
						className="input"
					/>
					<Form.Input
						type="text"
						placeholder="Pérez"
						name="apellido_paterno"
						label="Apellido paterno *"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.apellido_paterno}
						error={
							formik.touched.apellido_paterno && formik.errors.apellido_paterno
						}
						className="input"
					/>
					<Form.Input
						type="text"
						placeholder="López"
						name="apellido_materno"
						label="Apellido materno"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.apellido_materno}
						error={
							formik.touched.apellido_materno && formik.errors.apellido_materno
						}
						className="input"
					/>
					<Form.Input
						type="text"
						placeholder="ABCD123456XYZ (10 - 13 caracteres)"
						name="rfc"
						label="Registro Federal de Contribuyentes (RFC) **"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.rfc}
						error={formik.touched.rfc && formik.errors.rfc}
						className="input"
					/>
					<Form.Input
						type="text"
						placeholder="ABCD123456HDFLRN00 (18 caracteres)"
						name="curp"
						label="Clave Única de Registro de Población (CURP) **"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.curp}
						error={formik.touched.curp && formik.errors.curp}
						className="input"
					/>
					<Form.Input
						type="text"
						placeholder="1234567890 (11 caracteres)"
						name="nss"
						label="Número de Seguridad Social (NSS) **"
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.nss}
						error={formik.touched.nss && formik.errors.nss}
						className="input"
					/>
					<label htmlFor="entidades_federativas" className="labels">
						Entidades federativas *
					</label>
					<Dropdown
						name="entidades_federativas"
						id="entidades_federativas"
						placeholder="Selecciona una entidad federativa"
						fluid
						multiple
						selection
						options={estadosRepublicaMexicana}
						onChange={(_e, data) =>
							formik.setFieldValue("entidades_federativas", data.value)
						}
						onBlur={formik.handleBlur}
						value={formik.values.entidades_federativas}
						error={
							formik.touched.entidades_federativas &&
							formik.errors.entidades_federativas
						}
						className="input"
					/>
					<div>
						<Button
							type="button"
							onClick={() => handleCancelar()}
							id="secondary-button"
						>
							Cancelar
						</Button>
						<Button
							type="submit"
							onClick={formik.handleSubmit}
							id="primary-button"
						>
							Guardar
						</Button>
					</div>
				</Form>
				<div className="text">* Campos obligatorios</div>
				<div className="text">
					** Puede guardar un estudio sin estos campos, pero no se puede enviar
					hasta que estén completos y correctos
				</div>
			</div>
			<br />
		</Container>
	);
};

export default NuevoEstudio;
