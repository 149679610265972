import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";

import Login from "../features/Auth/components/Login/Login";

import Dashboard from "../features/Administracion/Dashboard";
import Lista from "../features/Administracion/Lista/Lista";
import DetalleEstudio from "../features/Shared/components/DetalleEstudio/DetalleEstudio";
import DetallePP from "../features/Shared/components/DetallePP/DetallePP";
import EditaPP from "../features/Shared/components/EditaPP/EditaPP";

export const RouterAdministracion = () => {
  let authState = useSelector((state) => state.auth.authState);
  return (
    <Routes>
      {authState ? (
        <Route element={<ProtectedRoute />}>
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/lista" element={<Lista />} />
          <Route exact path="/detalles" element={<DetalleEstudio />} />
          <Route exact path="/DetallePP" element={<DetallePP />} />
          <Route exact path="/EditaPP" element={<EditaPP />} />
        </Route>
      ) : (
        <>
          <Route index element={<Login />} />
          <Route path="*" element={<Login />} />
        </>
      )}
    </Routes>
  );
};
