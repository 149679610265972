import { useNavigate, useSearchParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  camposPPs,
  urlBaseAdministracion,
} from "../../../../constants/constants";
import { Button, Dropdown, Form, Table } from "semantic-ui-react";
import {
  useCreateOrUpdateMutation,
  useGetDetailsQuery,
} from "../../../../store/pps/PpsApi";
import { useCallback } from "react";
import { toast } from "react-toastify";

const EditaPP = () => {
  const [searchParams] = useSearchParams();
  const estudios_id = searchParams.get("id");
  const navigate = useNavigate();
  const [createOrUpdate] = useCreateOrUpdateMutation();

  const { data: pp_data = [] } = useGetDetailsQuery(estudios_id, {
    refetchOnMountOrArgChange: true,
  });

  const campos = camposPPs.reduce((acc, campo) => {
    const timestampRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
    acc[campo.value] = timestampRegex.test(camposPPs[0]?.[campo.value])
      ? pp_data[0]?.[campo.value]?.split("T")[0] || ""
      : pp_data[0]?.[campo.value] || "";
    return acc;
  }, {});

  const yups = camposPPs.reduce((acc, campo) => {
    acc[campo.value] = campo.yup;
    return acc;
  }, {});

  const formik = useFormik({
    initialValues: campos,
    validationSchema: Yup.object(yups),
    onSubmit: (values) => {
      values.estudios_id = estudios_id;
      Object.keys(values).forEach((key) => {
        const timestampRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;
        if (timestampRegex.test(values[key])) {
          values[key] = values[key].split("T")[0];
        }
      });
      createOrUpdate(values);
      setTimeout(() => {
        notifySaved();
        navigate(`${urlBaseAdministracion}/DetallePP?id=${estudios_id}`);
      }, 1000);
    },
  });

  const notifySaved = useCallback(() => {
    toast.success("Registro guardado correctamente");
  }, []);
  const handleCancelar = () => {
    navigate(`${urlBaseAdministracion}/DetallePP?id=${estudios_id}`);
  };

  return (
    <>
      <Form onSubmit={formik.handleSubmit} className="cargaDatosPP">
        <div className="register__subtitle">Captura de datos del PP</div>
        <Table striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Campo</Table.HeaderCell>
              <Table.HeaderCell>Valor</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {camposPPs.map((campo) => {
              return campo.type === "dropdown" ? (
                <>
                  <Table.Row key={campo.value}>
                    <Table.Cell>{campo.text}</Table.Cell>
                    <Table.Cell>
                      <Dropdown
                        id={campo.value}
                        key={campo.value}
                        name={campo.value}
                        label={campo.text}
                        fluid
                        selection
                        options={campo.options}
                        onChange={(_e, { value }) =>
                          formik.setFieldValue(campo.value, value)
                        }
                        value={formik.values[campo.value]}
                        error={
                          formik.touched[campo.value] &&
                          formik.errors[campo.value]
                        }
                        onFocus={formik.handleFocus}
                        onBlur={formik.handleBlur}
                      />
                    </Table.Cell>
                  </Table.Row>
                </>
              ) : (
                <>
                  <Table.Row key={campo.value}>
                    <Table.Cell>{campo.text}</Table.Cell>
                    <Table.Cell>
                      <Form.Input
                        type={campo.type}
                        id={campo.value}
                        key={campo.value}
                        name={campo.value}
                        // label={campo.text}
                        placeholder={campo.text}
                        onChange={formik.handleChange}
                        value={
                          campo.type === "date"
                            ? formik.values[campo.value]?.split("T")[0]
                            : formik.values[campo.value]
                        }
                        error={
                          formik.touched[campo.value] &&
                          formik.errors[campo.value]
                        }
                        onFocus={formik.handleFocus}
                      />
                    </Table.Cell>
                  </Table.Row>
                </>
              );
            })}
          </Table.Body>
        </Table>

        <div>
          <Button
            type="button"
            onClick={() => handleCancelar()}
            id="secondary-button"
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            onClick={formik.handleSubmit}
            id="primary-button"
          >
            Enviar
          </Button>
        </div>
      </Form>
    </>
  );
};

export default EditaPP;
