import { useSelector } from "react-redux";
import { Container } from "semantic-ui-react";
import ListaEstudios from "../../Shared/components/ListaEstudios/ListaEstudios";

import "./Reprocesos.scss";

const Reprocesos = () => {
	const user_id = parseInt(
		useSelector((state) => state.auth.id),
		10
	);
	return (
		<Container fluid className="container">
			<div className="header">Estudios - Reprocesos</div>
			<div className="captura">
				<ListaEstudios status="5" user_id={user_id} />
			</div>
		</Container>
	);
};

export default Reprocesos;
