import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseurl } from "../../constants/constants";

export const StudiesApi = createApi({
  reducerPath: "studiesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseurl}`,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Studies"],
  endpoints: (builder) => ({
    getList: builder.query({
      query: ({ asesor, status }) => {
        return {
          url: `/studies/getList`,
          method: "GET",
          params: { asesor, status },
        };
      },
      providesTags: ["Studies"],
    }),
    getAll: builder.query({
      query: () => `/studies/getAll`,
      method: "GET",
    }),
    getDetails: builder.query({
      query: (id) => `/studies/${id}`,
      method: "GET",
    }),
    createStudy: builder.mutation({
      query: (body) => ({
        url: `/studies/createStudy`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Studies"],
    }),
    updateStudy: builder.mutation({
      query: (body) => ({
        url: `/studies/update`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Studies"],
    }),
    changeStatus: builder.mutation({
      query: (body) => {
        // console.log({ body });
        return {
          url: `/studies/changeStatus`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: ["Studies"],
    }),
    deleteStudy: builder.mutation({
      query: (id) => ({
        url: `/studies/deleteStudy/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Studies"],
    }),
    getStatuses: builder.query({
      query: () => `/studies/getStatuses`,
      method: "GET",
    }),
    uploadFile: builder.mutation({
      query: (body) => {
        const formData = new FormData();
        formData.append("image", body.image);
        formData.append("id", body.id);
        formData.append("tipoImagen", body.tipoImagen);
        return {
          url: `/studies/uploadFile`,
          method: "POST",
          body: formData,
        };
      },
    }),
    getFiles: builder.query({
      query: (id) => `/studies/getFiles/${id}`,
      method: "GET",
    }),
    getLFiles: builder.query({
      query: (id) => `/studies/getFiles/${id}`,
      method: "GET",
    }),
  }),
});

export const {
  useGetListQuery,
  useGetAllQuery,
  useGetDetailsQuery,
  useCreateStudyMutation,
  useUpdateStudyMutation,
  useChangeStatusMutation,
  useDeleteStudyMutation,
  useGetStatusesQuery,
  useUploadFileMutation,
  useGetFilesQuery,
  useLazyGetLFilesQuery,
} = StudiesApi;
